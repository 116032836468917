//TODO: Handle this better for future multi-theming

@import "node_modules/@quantive/ui-kit/src/less/ui-kit.less";
@import "node_modules/@quantive/ui-kit/src/less/default/default.less";
/* Import our overriden theme */

/* import components here */

@import (multiple) "base/mixins/components/index.less";
/* Import overridden mixins */
@import (multiple) "base/mixins/spacing/spacing.less";
/* Import overridden mixins */

@import "base/overrides/component-overrides.less";
/* Override Component Global Behavior */

@import "base/overrides/global-overrides.less"; /* Override Global Behavior */

.ant-form-item .ant-select {
  width: 100%;
}

/* Global styles for native html elements (e.g. anchor)  */
@import "packages/webapp/src/app/ui/link/link.less";
@import "packages/webapp/src/app/ui/input/components/validation-hint/validation-hint.global.less";
